// https://paletton.com/#uid=43l0u0khnTe5v+4c4XalSNRq7FZ


// colors
$turquoise-blue: #6CDAE7;
$aquamarine: #66FCF1;
$keppel: #45A29E;

// whites
$white: #FFFFFF !default;
$off-white: #EEF0F3;
$mischka: darken($off-white, 10%);
$cadet-blue: darken($off-white, 20%);
$foo: darken($off-white, 40%);

$silver-sand: #C5C6C7;


// grays
$woodsmoke: #0B0C10;
//$ebony-clay: #1F2833;
$ebony-clay: lighten($woodsmoke, 10%);
$bright-grey: lighten($woodsmoke, 20%);
//$foo: lighten($woodsmoke, 40%);


// special
$error: #ff7f75;
$green: #25c48d;


// export for use in JS
:export {
  turquoiseBlue: $turquoise-blue;
  aquamarine: $aquamarine;
  keppel: $keppel;

  white: $white;
  offWhite: $off-white;
  mischka: $mischka;
  cadetBlue: $cadet-blue;

  woodsmoke: $woodsmoke;
  ebonyClay: $ebony-clay;
  brightGrey: $bright-grey;
}