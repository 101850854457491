

// breakpoint values
$phone-only: 539.99px;
$tablet-portrait-up: 540px;
$tablet-landscape-up: 768px;
$desktop-up: 1030px;
$big-desktop-up: 1350px;

// opposites
//$tablet-portrait-down: 539.999px;
$tablet-landscape-down: 767.999px;
$desktop-down: 1029.999px;
$big-desktop-down: 1349.999px;


// mixins
@mixin phone-only {
  @media (max-width: $phone-only) { @content; }
}
@mixin tablet-portrait-up {
  @media (min-width: $tablet-portrait-up) { @content; }
}
@mixin tablet-landscape-up {
  @media (min-width: $tablet-landscape-up) { @content; }
}
@mixin desktop-up {
  @media (min-width: $desktop-up) { @content; }
}
@mixin big-desktop-up {
  @media (min-width: $big-desktop-up) { @content; }
}


// so they can accessed in JS
//:export {
//  phoneOnly: $phone-only;
//  tabletPortraitUp: $tablet-portrait-up;
//  tabletLandscapeUp: $tablet-landscape-up;
//  desktopUp: $desktop-up;
//  bigDesktopUp: $big-desktop-up;
//}



//@mixin for-phone-only {
//  @media (max-width: 599.99px) { @content; }
//}
//@mixin for-tablet-portrait-up {
//  @media (min-width: 600px) { @content; }
//}
//@mixin for-tablet-landscape-up {
//  @media (min-width: 900px) { @content; }
//}
//@mixin for-desktop-up {
//  @media (min-width: 1200px) { @content; }
//}
//@mixin for-big-desktop-up {
//  @media (min-width: 1800px) { @content; }
//}