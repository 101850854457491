
@use './breakpoints';


/* Fonts */

// 'Roboto Condensed', sans-serif
//@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&display=swap');
//$roboto: 'Roboto', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
$roboto: 'Inter', sans-serif;

// 'Roboto', sans-serif
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');
$roboto-condensed: 'Roboto Condensed', sans-serif;

// DM Serif Display (Quote font)
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@1&display=swap');
$dm-serif-display: 'DM Serif Display', serif;


/* Font Sizes */

//$small: 12px;
//$medium: 16px;
//$large: 20px;
//$xlarge: 26px;



//$small: 0.75rem;
//$medium: 1rem;
//$large: 1.25rem;
//$xlarge: 1.5rem;
//$xxlarge: 3.8rem;


@mixin size($size) {
  @if $size == 'xsmall' {
    font-size: 0.7rem;
    //font-size: 0.88rem;
  } @else if $size == 'small' {
    font-size: 0.8rem;
  } @else if $size == 'medium' {
    font-size: 1rem;
  } @else if $size == 'large' {
    font-size: 1.5rem;
  } @else if $size == 'xlarge' {
    font-size: 2.2rem;
  } @else if $size == 'xxlarge' {
    font-size: 3.8rem;
  } @else {
    @error 'invalid font size';
  }
}

@mixin weight($weight) {
  @if $weight == 'thin' {
    font-weight: 100;
  } @else if $weight == 'light' {
    font-weight: 300;
  } @else if $weight == 'regular' {
    font-weight: 400;
  } @else if $weight == 'medium' {
    font-weight: 500;
  } @else if $weight == 'bold' {
    font-weight: 700;
  } @else {
    @error 'invalid font weight';
  }
}


$iconSmall: 14px;
$iconMedium: 20px;
$iconLarge: 30px;
$iconXLarge: 50px;
$iconXXLarge: 80px;


//--iconsize-s: 20px;
//--iconsize-m: 24px;
//--iconsize-l: 30px;