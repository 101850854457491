@use './modules/fonts';
@use './modules/colors';
@use './modules/breakpoints';



:root {
  //font-size: 16px;

  //@include breakpoints.phone-only {
  //  font-size: 8px;
  //}
  //
  //@include breakpoints.tablet-portrait-up {
  //  font-size: 10px;
  //}
  //
  //@include breakpoints.tablet-landscape-up {
  //  font-size: 14px;
  //}
  //
  //@include breakpoints.desktop-up {
  //  font-size: 14px;
  //}
  //
  //@include breakpoints.big-desktop-up {
  //  font-size: 16px;
  //}
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


/* Body, Nav, Header, Main */
body {
  //background: colors.$ebony-clay;
  background: colors.$off-white;
  font-family: fonts.$roboto;
}

//nav {
//  @include breakpoints.phone-only {
//    width: 90px;
//  }
//
//  @include breakpoints.tablet-portrait-up {
//    width: 160px;
//  }
//
//  @include breakpoints.tablet-landscape-up {
//    width: 190px;
//  }
//
//  @include breakpoints.desktop-up {
//    width: 190px;
//  }
//
//  @include breakpoints.big-desktop-up {
//    width: 230px;
//  }
//}

//main {
//  color: colors.$woodsmoke;
//
//  @include breakpoints.phone-only {
//    margin-left: 90px;
//  }
//
//  @include breakpoints.tablet-portrait-up {
//    margin-left: 160px;
//  }
//
//  @include breakpoints.tablet-landscape-up {
//    margin-left: 190px;
//  }
//
//  @include breakpoints.desktop-up {
//    margin-left: 190px;
//  }
//
//  @include breakpoints.big-desktop-up {
//    margin-left: 230px;
//  }
//
//
//  & > div {
//    padding-top: 70px;
//  }
//}


/* Fonts */

h1, h2, h3, .h1, .h2, .h3 {
  font-family: fonts.$roboto-condensed;
}
h1, .h1 {
  //@include fonts.size('xxlarge');
}
h2, .h2 {
  //@include fonts.size('xlarge');
}
h3, .h3 {
  //@include fonts.size('large');
}

p, .p {
  //@include fonts.size('medium');
}

small, .small {
  //@include fonts.size('small');
}

a, .a {
  //@include fonts.size('medium');
  color: colors.$bright-grey;
  text-decoration: none;
}

a:hover, .a:hover {
  //color: darken(colors.$white, 5%);
  color: colors.$woodsmoke;
}


.icon-medium {
  height: fonts.$iconMedium;
}

hr {
  border-radius: 0;
  border: 0.5px solid colors.$mischka;
  margin: 10px 0;
}


/*  */
$item_width: 208px; // 208 reel width + 5px padding either side (total 10px padding)
.container {
  position: relative;
  margin: 0 auto;
  //width: 280px;
  //width: calc(100% - 100px);

  //background: pink;

  @include breakpoints.phone-only {
    width: $item_width*1;
  }

  @include breakpoints.tablet-portrait-up {
    width: $item_width*1;
  }

  @include breakpoints.tablet-landscape-up {
    width: $item_width*2 + 10px*1;
  }

  @include breakpoints.desktop-up {
    width: $item_width*3 + 10px*2;
  }

  @include breakpoints.big-desktop-up {
    width: $item_width*5 + 10px*4;
  }
}


.text-error {
  color: colors.$error;
}




///*------GLOBALS------*/
///*@import url('header.css');*/
///*@import url('nav.css');*/
//
//
//
///*------FONTS------*/
///* 'Roboto Condensed', sans-serif */
//@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&display=swap');
///* 'Roboto', sans-serif */
//@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
//
//
//
///*------DEFINITIONS------*/
//:root{
//  /* --color-1: #c40434; */
//
//  --color-1: hsl(182, 73%, 63%);
//  --color-1_dark: hsl(182, 73%, 40%);
//  --color-1_hs: 182, 73%;
//  /* --color-1: hsl(178, 94%, 77%); */
//  /* --color-1_dark: hsl(178, 94%, 50%); */
//  /* --color-1_raw: 178, 94%, 77%; */
//  --color-2: hsl(233, 73%, 63%);
//  /* --color-2: hsl(260, 100%, 66%); */
//  /* --color-2: #39A0ED; */
//
//  --color-dark1: hsl(220, 4%, 12%);
//  --color-dark2: hsl(227, 5%, 17%);
//  --color-dark3: hsl(210, 10%, 22%);
//  --color-dark4: hsl(207, 13%, 26%);
//
//  --color-light1: hsl(0, 0%, 100%);
//  --color-light2: hsl(0, 0%, 80%);
//  --color-light3: hsl(0, 0%, 60%);
//
//  --color-error: hsl(0, 75%, 55%);
//
//  --fontsize-s: 0.8rem;
//  --fontsize-m: 1rem;
//  --fontsize-l: 1.2rem;
//
//  /* --fontsize-h1: 3.8rem;
//  --fontsize-h2: 2.5rem;
//  --fontsize-h3: 1.9rem; */
//
//  --iconsize-s: 20px;
//  --iconsize-m: 24px;
//  --iconsize-l: 30px;
//
//}
//
//
//
///*------ ------*/
////* {
////  -webkit-box-sizing: border-box;
////  box-sizing: border-box;
////}
//
//
////body{
////  position: relative;
////
////  font-family: 'Roboto', sans-serif;
////  font-weight: 400;
////
////  /*background: var(--color-dark3);*/
////}
//
//nav {
//  width: 230px;
//}
//
//main{
//  flex: 1;
//
//  padding-top: 90px;
//  margin-left: 230px;
//  min-height: 100vh;
//
//  background: colors.$woodsmoke;
//  color: colors.$white;
//}
//
//
//ul{
//  margin-bottom: 0;
//}
//
//
//#templates{
//  display: none;
//}
//
//
//
///*------TEXT------*/
//h1,h2,h3,h4,p,a{
//  margin: 0;
//  padding: 0;
//}
//h1,h2,h3,h4{
//  font-family: 'Roboto Condensed', sans-serif;
//}
//h1{
//  /* font-size: 60px; */
//  font-size: 3.8rem;
//  font-weight: 700;
//  line-height: 50px;
//}
//h1.text-thin{
//  font-weight: 400;
//}
//h2{
//  /* font-size: 40px; */
//  font-size: 2.5rem;
//  font-weight: 700;
//}
//h2.text-thin{
//  font-weight: 400;
//}
//h3{
//  /* font-size: 30px; */
//  font-size: 1.9rem;
//  font-weight: 700;
//}
///* h4{
//	font-size: 30px;
//	font-weight: 300;
//} */
//
//p{
//  /* font-size: 16px; */
//}
//
//
///*a{*/
///*  color: var(--color-light1);*/
///*  cursor: pointer;*/
///*}*/
///*a:hover{*/
///*  text-decoration: none;*/
///*  color: var(--color-light2);*/
///*}*/
//
//
//
//
///*------RESPONSIVE------*/
///*.page-container{*/
///*  position: relative;*/
///*  margin: 0 auto;*/
//
///*  width: 280px; !*1 reel*!*/
///*}*/
///*nav{*/
///*  !* width: 180px;*/
///*  font-size: 1.7rem; *!*/
///*  font-size: 0.7rem;*/
///*  width: 90px;*/
///*}*/
///*nav .sidebar-nav li{*/
///*  margin: 5px 5px 5px auto;*/
///*  line-height: 0.8rem;*/
///*}*/
///*nav .siderbar-footer{*/
///*  font-size: 0.5rem;*/
///*}*/
///*nav .brand-desktop{*/
///*  display: none;*/
///*}*/
///*header .search-bar{*/
///*  width: 150px;*/
///*}*/
///*main{*/
///*  margin-left: 90px;*/
///*}*/
//
///*!*Small devices (landscape phones, 576px and up, 1 reel)*!*/
///*@media(min-width: 540px){*/
///*  .page-container{*/
///*    width: 280px;*/
///*  }*/
///*  nav{*/
///*    width: 160px;*/
///*    font-size: 1.2rem;*/
///*  }*/
///*  nav .sidebar-nav li{*/
///*    margin: 10px 10px 10px auto;*/
///*    line-height: 1.2rem;*/
///*  }*/
///*  nav .sidebar-footer{*/
///*    font-size: 0.8rem;*/
///*  }*/
///*  nav .brand-desktop{*/
///*    display: inline;*/
///*  }*/
///*  nav .brand-mobile{*/
///*    display: none;*/
///*  }*/
///*  header .search-bar{*/
///*    width: 250px;*/
///*  }*/
///*  main{*/
///*    margin-left: 160px;*/
///*  }*/
///*}*/
//
///*!*Medium Devices (tablets, 768px and up, 2 reels)*!*/
///*@media(min-width: 768px){*/
///*  !* :root{*/
///*      font-size: 8px;*/
///*  } *!*/
//
///*  .page-container{*/
///*    width: 560px; !* 270*2 + 4*5 (270reel width*2reels + 10padding*4 gaps between reels) *!*/
///*  }*/
///*  nav{*/
///*    width: 190px;*/
///*    font-size: 1.3rem;*/
///*  }*/
///*  nav .sidebar-nav li{*/
///*    line-height: 25px;*/
///*  }*/
///*  header .search-bar{*/
///*    width: 450px;*/
///*  }*/
///*  main{*/
///*    margin-left: 190px;*/
///*  }*/
///*}*/
///*!*Large devices (laptop, 1024px and up, 3 reels)*!*/
///*@media (min-width: 1030px){*/
///*  !* :root{*/
///*      font-size: 12px;*/
///*  } *!*/
//
///*  .page-container{*/
///*    width: 840px;*/
///*  }*/
///*  nav{*/
///*    width: 190px;*/
///*    font-size: 1.3rem;*/
///*  }*/
///*  !* header .search-bar{*/
///*      width: 500px;*/
///*  } *!*/
///*  main{*/
///*    margin-left: 190px;*/
///*  }*/
///*}*/
///*!*XLarge devices (large desktops, 1350px and up, 4 reels)*!*/
///*@media (min-width: 1350px){*/
///*  !* :root{*/
///*      font-size: 16px;*/
///*  } *!*/
//
///*  .page-container{*/
///*    width: 1120px;*/
///*  }*/
///*  nav{*/
///*    width: 230px;*/
///*    font-size: 1.7rem;*/
///*  }*/
///*  main{*/
///*    margin-left: 230px;*/
///*  }*/
///*}*/
//
///*
//// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) { ... }
//// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { ... }
//// Large devices (desktops, 992px and up)
//@media (min-width: 992px) { ... }
//// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }
//*/
//
//
//
//
//
//
///*------BOOTSTRAP OVERRIDE------*/
//.bg-dark{
//  background: var(--color-dark-2)!important;
//}
//.bg-primary{
//  background: var(--color-1)!important;
//}
//
//.text-primary{
//  color:  var(--color-1)!important;
//}
//.text-danger{
//  color: var(--color-error)!important;
//}
//
//.progress-bar{
//  background: var(--color-1);
//}
//
//.btn-outline-light{
//  background: none;
//  border: 2px solid hsla(0, 100%, 100%, 0.6);
//  color: hsla(0, 100%, 100%, 0.6);
//  padding: 6px 10px;
//  border-radius: 10px;
//
//  -webkit-transition: all 0.2s ease-in-out;
//
//  -o-transition: all 0.2s ease-in-out;
//
//  transition: all 0.2s ease-in-out;
//}
//.btn-outline-light:hover{
//  /* background: hsla(0, 100%, 100%, 0.2); */
//  background: none;
//  border: 2px solid hsla(0, 100%, 100%, 1);
//  color: hsla(0, 100%, 100%, 1);
//}
//
//.btn{
//  margin: 0!important;
//  padding: 6px 10px!important;
//}
//
//
//
//
//
//
//
//
///*------FORMS------*/
//.input-container {
//  width: 100%;
//  margin-bottom: 15px;
//  border-bottom: 2px solid var(--color-dark-3);
//  padding-left: 5px;
//  display: -webkit-box;
//  display: -ms-flexbox;
//  display: flex;
//  -webkit-box-orient: horizontal;
//  -webkit-box-direction: reverse;
//  -ms-flex-direction: row-reverse;
//  flex-direction: row-reverse;
//}
//.input-container i {
//  /* padding: 10px; */
//  /* min-width: 40px; */
//  /* text-align: left; */
//  padding-right: 10px;
//  line-height: 2	;
//  font-size: 20px!important;
//  cursor: pointer;
//}
//.input-container input {
//  width: 100%;
//  /* padding: 10px; */
//  padding-left: 0;
//  outline: none;
//  background: none;
//  border: none;
//  /* color: var(--color-dark-1)!important; */
//}
//.input-container input:focus ~ i{
//  color: var(--color-dark-1);
//}
//.btn {
//  margin-top: 15px;
//  padding: 10px 0;
//  border: none;
//  border-radius: 5px;
//}
//
//
//.custom-select{
//  border: 2px solid var(--color-dark-3);
//}
//
//
//input:-webkit-autofill,
//input:-webkit-autofill:hover,
//input:-webkit-autofill:focus,
//textarea:-webkit-autofill,
//textarea:-webkit-autofill:hover,
//textarea:-webkit-autofill:focus,
//select:-webkit-autofill,
//select:-webkit-autofill:hover,
//select:-webkit-autofill:focus {
//  /* border: 1px solid green; */
//  /* -webkit-text-fill-color: green; */
//  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
//  -webkit-transition: background-color 5000s ease-in-out 0s;
//  -o-transition: background-color 5000s ease-in-out 0s;
//  transition: background-color 5000s ease-in-out 0s;
//}
//
//textarea{
//  background: none;
//  border: 2px solid var(--color-dark-3);
//  width: 100%;
//  resize: vertical;
//  min-height: 200px;
//  padding: 5px;
//}
//
//label{
//  font-weight: 700;
//  margin: 0;
//}
//
//#submitBtnLoader{
//  display: none;
//}
//
///*------FORMS end------*/
//
//
//
//
///* Utility Classes */
//
//.text-fade{
//  color: hsla(0, 0%, 0%, 0.5);
//}
//.text-fade-light{
//  color: hsla(0, 100%, 100%, 0.6);
//}
//
///* .shadow{
//	-webkit-box-shadow: 0px 4px 6px 0 hsla(0, 0%, 0%, 0.2)!important;
//	        box-shadow: 0px 4px 6px 0 hsla(0, 0%, 0%, 0.2)!important;
//} */
//.shadow-all{
//  -webkit-box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.4)!important;
//  box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.4)!important;
//}
//
//.bg-light-opaque{
//  background: hsla(0, 0%, 100%, 0.4);
//}
//
//.btn-nostyle{
//  background: none;
//  border: none;
//}
//
//
//
//.flash{
//  display: none;
//}
